#footer {
    background: $mb-footer-bg;
    padding: $pad-large 0;
    color: $mb-footer-text;
    border-top: 10px solid $mb-footer-colour-bar;

    a, p, h3, h4, h5 {
        color: $mb-footer-text;
    }

    h3, h4, h5 {
        //text-transform: uppercase;
    }

    .social-media-links {
        text-align: left;

        //li {
        //    display: inline-block;
        //}
    }

    .fa {
        color: $mb-footer-text;
        background: $mb-footer-fa-bg;
        padding: 4px;
        border-radius: 100%;
    }

    .ec-disclaimer {
        img {
            margin-top: $pad-small;
        }
    }

    .footer-col {
        margin-bottom: $pad-medium;
    }

}