body {
    font-weight: 400;

}

.main-content,
.standard-text-block {
    a:not(.btn) {
        text-decoration: underline;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
}

h1 {
    margin-bottom: $pad-large;
}

h3 {
    font-weight: 600;
}

h2,h3 {
    margin-bottom: $pad-small;
    margin-top: $pad-small;
}

h2 {

    &:first-child {
        margin-top: 0;
    }

    .layout-sidebar & {
        margin-bottom: $pad-medium;
        padding-bottom: $pad-small;
        //border-bottom: 2px solid $primary;
    }
}

p {
    margin-top: $pad-small*1.5;
}

h4,h5,h6 {
    margin-top: $pad-small;
    margin-bottom: $pad-small / 2;
}

hr {
  border-top: 2px solid $primary
}

@media (max-width: $screen-xs-max) {
    h1 {
        font-size: $font-size-h1 *.8;
    }

    h2 {
        font-size: $font-size-h2 *.8;
    }

    h3 {
        font-size: $font-size-h3 *.8;
    }

    h4 {
        font-size: $font-size-h4 *.8;
    }
}

.alt-link,
.alt-link * {
    color: $secondary;

    &:hover {
        color: darken($secondary, 15%);
    }
}

.reverse-link {
    color: $white;
}

.reverse {
    color: $white;
}