.header {
    padding: $pad-large 0;
    border-top: 10px solid $mb-header-colour-bar;

    .hdr-logo {
        margin-bottom: $pad-large;
        max-width: 400px;
    }

    .site-tagline {
        font-size: $font-size-large;
        color: $primary;
        font-weight: 400;
        //max-width: 300px;
        max-width: 500px;
        //padding-top: 10px;
        margin-top: 0px;

        @media(max-width: 400px) {
            max-width: 100%;
            width: 100%;
        }

        @media (max-width: $screen-xs-max) {
            display: inline-block;
            z-index: 10;
            position: relative;
        }

    }

    .social-media-links {
        @media(min-width: $screen-sm-min) {
            float: right;
            clear: right;
        }

        li {

            @media(max-width: $screen-ms-max) {
                text-align: right;
            }
        }

        //li {
        //    display: inline-block;
        //}

        .fa {
            color: $secondary;
            background: transparent;
        }

        .fa {
            font-size: $font-size-h2;
            padding: 5px;
            border-radius: 100%;
            background: $white;
        }

        a {
            font-size: $font-size-h3;
            
            &:hover {
                text-decoration: none;

                .fa {
                    background: $secondary;
                    color: $white;
                }
            }
        }
    }



    .breadcrumb {
        margin-bottom: 0;
        padding-bottom: 0;
        padding-left: 0;
    }

    #searchform {
        display: inline-block;
        float: right;
        margin-bottom: $pad-small;

        @media(min-width: $screen-sm-min) {
            .input-group {
                max-width: 300px;
            }
        }

        button {
            //text-transform: uppercase;
        }
    }

    #s {
        right: $pad-small;
        background: $grey;
        font-weight: 400;
        position: relative;
        padding-left: 35px;
    }

    .input-group {
        &:before {
            content: "\f002";
            font-family: FontAwesome;
            color: $primary;
            font-size: 18px;
            padding-right: 0.5em;
            position: absolute;
            top: 5px;
            left: 0;
            z-index: 10;
        }
    }

}