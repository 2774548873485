@mixin customli {
    margin-right: 10px;
    color: white;
    font-weight: 700;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    padding-top: 0px;
    text-indent: 0;
}
.work-package,
.standard-text-block,
.news-archives {
    &>ol,
    &>ul {
        margin: 0 0 $pad-small $pad-large;
        text-indent: -$pad-large;
        padding: 0;
        list-style-type: none;

        li {
            margin-bottom: 10px;
        }

        &>li {
            counter-increment: step-counter;

            &::before {
                @include customli;
                background-color: $mb-li-bg;
                content: counter(step-counter);
            }

            &>ol,
            &>ul {
                list-style-type: none;
                padding-left: $pad-large;
                margin-top: 10px;

                &>li {
                    counter-increment: child-step-counter;

                    &::before {
                        @include customli;
                        content: counter(child-step-counter, lower-roman);
                        background-color: $mb-li-nested-bg;
                        font-weight: 100;
                    }
                }
            }
        }
    }

    // override this for ul's to not show the counter
    ul li::before {
        content: "" !important;
        position: relative;
        top: 2px;
        width: 15px !important;
        height: 15px !important;
    }
}
