@mixin list-header($background) {
    background: $background;
    padding: $pad-small;

    h2 {
        margin-bottom: 0;
        display: inline-block;
        font-weight: 600;
        padding: 0;
    }

    a {
        color: $white;
    }

    .fa {
        margin-left: $pad-small;
    }
}

@mixin list-body($background, $titles) {
    background: $background;
    padding: $pad-small;

    h3 {
        color: $titles;
        margin-top: 0;
    }
}