.events-list-header {
    @include list-header($secondary);
}

.events-list-body {
    @include list-body($secondary-pale, $secondary);

    h3 {
        min-height: 34px;
    }

    ul {
        margin-bottom: 0;
    }

    li {
        margin-bottom: $pad-medium;
    }

    @media(min-width: $screen-sm-min) {
        ul {
            display: flex;
        }

        li {
            flex-basis: 30%;
            flex-grow: 1;
            padding: 0 $pad-large 0 $pad-small;
            border-left: 1px solid $secondary;
            margin-bottom: 0;

            &:first-child {
                border: none;
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            p:last-child {
                margin-bottom: 0;
            }
        }
    }

    .description {
        // a crude but effective way of limiting the amount of content that displays in the description
        height: 65px;
        display: block;
        overflow: hidden;
        margin-bottom: $pad-small;


        // Break long words such as URLs to wrap neatly in the events banner

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
}