/*
Theme Name: MADE Base
Theme URI: http://made-agency.co.uk
Author: MADE Agency
*/

// Google font
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');


// Variables
@import "madebase-variables";
@import "bootstrap_madebase_variables";

// Vendor
@import "vendor/bootstrap";
@import "vendor/bootstrap-grid-ms.scss";
@import "vendor/font-awesome";
@import "vendor/bxslider";
@import "vendor/ajax-loader/spinners/2-double-bounce";
@import "vendor/featherlight";

// MADE
@import "made-standard";
@import "wordpress-defaults";

// Global
@import "global/global";
@import "global/typography";
@import "global/layout";
@import "global/mixins";
@import "global/animations";

//sections
@import "sections/header";
@import "sections/footer";
@import "sections/sidebar";

// components
@import "components/content-blocks";
@import "components/content-feed";
@import "components/image-links";
@import "components/banner-image";
@import "components/lists";
@import "components/buttons";
@import "components/navbar";
@import "components/news";
@import "components/tease";
@import "components/grouped-content";
@import "components/showcase";
@import "components/partners-map";
@import "components/calendar";
@import "components/work-packages";
@import "components/events-list";
@import "components/post-images";
@import "components/timestamp";
@import "components/tables";
